.grid-container .MuiDataGrid-root .MuiDataGrid-editCellInputBase.Mui-focused input {
  padding: 0 2px;
}

.cancelled-row-cell {
  background-color: rgb(237, 139, 0, 0.12);
}

.closed-row-cell {
  background-color: rgb(0, 75, 135, 0.07);
}

.editable-cell-error {
  border-left: 5px solid #da291c;
}

.clickable-cell {
  font-size: 18px;
  color: rgb(218, 41, 28, 0.7);
  cursor: pointer;
}
.clickable-cell:hover {
  font-size: 19px;
  outline: 2px dotted rgb(218, 41, 28);
  outline-offset: -2px;
  color: rgb(218, 41, 28);
}
