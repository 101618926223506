/* General */
:root {
    --bgcolor: #f7f4f0;
    --bordercolor: #edd5b9;
    --fontsize-xs: 0.7rem;
    --fontsize-s: 0.8rem;
    --fontsize-m: 0.9rem;
    --fontsize-l: 1.1rem;
    --fontsize-xl: 1.4rem;
    --fontfamily: 'Segoe UI', Tahoma, Verdana, sans-serif;
    --hovershadow: 0px 0px 4px 1px #ccc;
    --itemgroupshadow: 0px 0px 6px 0px #edc896;
}

div.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

a.link-button {
    text-decoration: none;
    padding: 6px;
    border: 1px solid var(--bordercolor);
    font-family: var(--fontfamily);
}

a.link-button:hover {
    box-shadow: var(--hovershadow);
}

input.visent,
select.visent {
    display: block;
    box-sizing: content-box;
    font-family: var(--fontfamily);
    font-size: var(--fontsize-m);
    margin: 2px 0 10px 0;
    padding: 2px 4px;
    border: 2px solid transparent;
    outline-style: none;
}

select.dropdown {
    font-family: var(--fontfamily);
    font-size: var(--fontsize-m);
}

input.visent:focus,
select.visent:focus {
    border-bottom: 2px solid #d9b284;
}


/* To use with element <Dialog> to increase standard dialog box width */

div.visent.dialog-s div[role="dialog"] {
    width: 600px;
    max-width: 600px;
}

div.visent.dialog-m div[role="dialog"] {
    width: 700px;
    max-width: 7900px;
}

div.visent.dialog-l div[role="dialog"] {
    width: 800px;
    max-width: 800px;
}

div.visent.dialog-xl div[role="dialog"] {
    width: 900px;
    max-width: 900px;
}

div.visent.dialog-xxl div[role="dialog"] {
    width: 1000px;
    max-width: 1000px;
}

/* Multi-line grid cell */
div.multi-line-cell {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3px;
}

div.multi-line-cell div {
    margin: 0;
    padding: 0;
    line-height: 1.6em;
}

/****************/
/** Edit Panel **/
/****************/
div.edit-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    padding: 6px;
    color: black;
    background-color: var(--bgcolor);
    border-style: solid;
    border-width: 2px;
    border-color: var(--bordercolor);
    border-radius: 8px;
    box-shadow: 4px 4px 12px 2px #aaa;
}

div.edit-header {
    padding: 10px;
    border-bottom: 2px solid var(--bordercolor);
    font-size: var(--fontsize-xl);
    font-family: var(--fontfamily);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

div.edit-header-section {
    padding: 10px;
    border-top: 1px solid var(--bordercolor);
    margin-top: 12px;
    font-size: var(--fontsize-l);
    font-family: var(--fontfamily);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

div.edit-contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
}

div.edit-contents div {
    font-family: var(--fontfamily);
    font-size: var(--fontsize-m);
}

div.edit-contents-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 4px 0;
    box-shadow: var(--itemgroupshadow);
}

div.edit-footer {
    display: block;
    position: relative;
    padding: 10px;
    margin: 10px 0;
    border-top: 2px solid var(--bordercolor);
}

div.edit-main form {
    display: grid;
}

div.edit-form-buttons-bar {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    padding: 10px;
    margin: 10px 0;
    border-top: 2px solid var(--bordercolor);
}

div.edit-main div.edit-form-buttons-bar button {
    background-color: #f1e7db;
}

div.edit-main div.edit-form-buttons-bar button:hover {
    background-color: #f1e7db;
    box-shadow: var(--hovershadow);
}

div.edit-main label {
    display: block;
    margin: 10px 0 4px 0;
    font-family: var(--fontfamily);
    font-size: var(--fontsize-xs);
}

div.edit-main .edit-input-error {
    background-color: rgb(206, 85, 85);
    color: white;
}

div.edit-main .error {
    background-color: rgb(196, 50, 50);
    color: rgb(248, 248, 169);
}

div.edit-dropdown {
    display: flex;
    justify-content: flex-end;
}

div.edit-dropdown input[type=search] {
    margin-bottom: 0;
}

div.edit-dropdown select {
    border: none;
    outline: solid 1px #eee;
}

div.edit-dropdown option:hover {
    background-color: #eee;
}

/* Inline checkboxes with labels */
div.edit-inline-checkboxes {
    display: flex;
    justify-content: space-around;
    margin: 10px 0 4px 0;
}

div.edit-inline-checkboxes label {
    display: inline-block;
    margin: 8px 4px;
    font-size: var(--fontsize-m);
}

div.edit-inline-checkboxes input[type=checkbox] {
    display: inline-block;
    margin: 8px 4px;
}

/* Table in edit panel */
div.edit-contents table * {
    font-family: var(--fontfamily);
    font-size: var(--fontsize-m);
    padding: 2px 8px;
}

table.edit-table th {
    text-align: left;
    background-color: #e7e2dc;
    border-bottom: solid 2px var(--bordercolor);
}

table.edit-table td {
    text-align: left;
    background-color: #efece9;
    border-bottom: solid 1px var(--bordercolor);
}

table.edit-table td.td-warning {
    color: #ab5518;
    background-color: #fffad8;
}

table.edit-table>tbody>tr>td:last-child {
    text-align: right;
    border-bottom: solid 1px var(--bordercolor);
}

table.edit-table td>input {
    box-sizing: border-box;
    width: 100%;
}

table.edit-table td>button {
    width: 65px;
}

/**************************************************/
/* Box Menu - navigation items on main Admin page */
/**************************************************/
div.box-menu {
    margin: 0 -10px 0 -10px;
}

div.box-menu a {
    display: inline-flex;
    text-decoration: none;
    margin: 10px;
}

div.box-menu a:hover {
    box-shadow: var(--hovershadow);
}

.box-menu-item {
    border: 1px solid var(--bordercolor);
    min-width: 250px;
    padding: 20px;
    background-color: var(--bgcolor);
    text-align: center;
}



/* Material UI Modifications */

div.MuiDataGrid-cellWithRenderer {
    justify-content: center;
}

span.MuiButton-label span.material-icons {
    margin-right: 8px
}

div.MuiFormGroup-root[role="radiogroup"] label {
    margin: 0;
}

div.MuiFormGroup-root[role="radiogroup"] .MuiTypography-body1 {
    font-family: var(--fontfamily);
    font-size: var(--fontsize-m);
}

/* MUI Dialog */
.MuiDialog-root.visent .MuiDialogTitle-root {
    padding: 10px 24px;
    background-color: var(--bgcolor);
}

.MuiDialog-root.visent .MuiDialogTitle-root .MuiTypography-root {
    font-weight: bold;
}

.MuiDialog-root.visent .MuiDialogContent-root {
    padding: 10px 24px;
}

.MuiDialog-root.visent .MuiDialogActions-root {
    padding: 4px 24px;
    background-color: var(--bgcolor);
}

/* Details Dialog - Header */

div.det-container div {
    font-family: var(--fontfamily);
    font-size: var(--fontsize-m);
}

div.det-container {
    display: flex;
    flex-direction: column;
}

div.det-item {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 12px 0;
    border-radius: 10px;
    box-shadow: 0 0 6px #aaa;
}

div.det-item-label,
div.det-item-value {
    padding: 6px;
}

div.det-item-label {
    display: flex;
    justify-content: flex-end;
    border-radius: 12px 0 0 12px;
    background-color: #f5f5f5;
    padding-right: 12px;
}

div.det-item-value {
    border-radius: 0 12px 12px 0;
    padding-left: 12px;
}

/* Details Dialog - JSON boxes */

div.json-box.before,
div.json-box.after {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 4px 0;
}

div.json-box.title {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-weight: bold;
    padding: 4px;
}

div.json-box.before {
    background-color: #f3e1e1;
}

div.json-box.before code {
    color: red;
    border-left: solid 2px #ccc;
}

div.json-box.after {
    background-color: #e1f3e1;
}

div.json-box.after code {
    color: darkgreen;
    border-left: solid 2px #ccc;
}

div.json-container code {
    padding: 6px;
}

/** Filter'n'Grid (fng) **/
div.fng {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

div.fng label,
div.fng input {
    font-family: var(--fontfamily);
    font-size: var(--fontsize-m);
}

div.fng-grid-group,
div.fng-side-filter {
    display: flex;
    flex-direction: column;
    margin: 0 4px;
    padding: 4px 8px;
    background-color: var(--bgcolor);
}

div.fng-filter-item {
    margin: 8px 0;
    padding: 4px 10px;
    box-shadow: var(--itemgroupshadow);
}

div.fng-filter-item>label {
    width: 100%;
    font-size: var(--fontsize-s);
    display: block;
    margin-bottom: 4px;
}

div.fng-filter-item input {
    margin-right: 10px;
}

div.fng-filter-item-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

button.fng-button {
    margin: 6px 0;
    border: solid 1px var(--bordercolor);
}

button.fng-button:hover {
    box-shadow: var(--hovershadow);
}