body {
  background-color: #ffffff !important;
  background: #e41f13; /* Old browsers */
  background: -moz-linear-gradient(top, #e41f13 0%, #f59b15 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e41f13 0%, #f59b15 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e41f13 0%, #f59b15 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e41f13', endColorstr='#f59b15',GradientType=0 ); /* IE6-9 */
  background-attachment: fixed;
  background-image: url(../img/gradient.png);
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 100% 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  color: #222222 !important;
}

#root {
  display: flex;
  flex-direction: column;
  height: 98vh;
}
